import React from 'react'
import { Helmet } from 'react-helmet';
import { useHandleLanguageChange } from '../utils/lng_get';
import { useTranslation } from 'react-i18next';

function Terms() {
    useHandleLanguageChange();
    const { t } = useTranslation();
    const termsContent = { __html: t('terms_content') };
    return (
        <>
            <Helmet>
                <title>{t('terms')}</title>
            </Helmet>
            <div className='nonecontent'>
                <b>{t('terms')}</b><br /><br />
                <div dangerouslySetInnerHTML={termsContent} />
            </div>
        </>
    )
}

export default Terms