import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import i18n from './i18n'; // Adjust the path according to your project structure

// List of valid languages
const validLanguages = ['en', 'ru', 'tk'];

export const useHandleLanguageChange = () => {
    const { lang_slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'tk';

        if (lang_slug && validLanguages.includes(lang_slug)) {
            if (lang_slug !== savedLanguage) {
                i18n.changeLanguage(lang_slug);
                localStorage.setItem('language', lang_slug);

                const newPath = `/${lang_slug}${window.location.pathname.replace(/^\/\w+/, '')}`;
                if (newPath !== window.location.pathname) {
                    navigate(newPath, { replace: true });
                }
            } else {
                i18n.changeLanguage(savedLanguage);
            }
        } else if (!lang_slug) {
            i18n.changeLanguage(savedLanguage);
            const newPath = `/${savedLanguage}${window.location.pathname.replace(/^\/\w+/, '')}`;
            navigate(newPath, { replace: true });
        } else {
            // If the language is invalid, revert to the saved language
            i18n.changeLanguage(savedLanguage);
            const newPath = `/${savedLanguage}${window.location.pathname.replace(/^\/\w+/, '')}`;
            navigate(newPath, { replace: true });
        }
    }, [lang_slug, navigate]);

    // Optional: Return a function for manual language change if needed
    const changeLanguageManually = (lang) => {
        if (validLanguages.includes(lang)) {
            i18n.changeLanguage(lang);
            localStorage.setItem('language', lang);
            const newPath = `/${lang}${window.location.pathname.replace(/^\/\w+/, '')}`;
            navigate(newPath, { replace: true });
        }
    };

    return { changeLanguageManually };
};
