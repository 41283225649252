const translations = {
    tk: {
        translation: {
            // home
            title: "GyzylAlma - Üstünlik açary!",
            description: "Okamagy halaýaňmy?! Jogap, HAWA bolsa, GyzylAlma programmasy hut seň üçin!",
            download_prompt: "GyzylAlma-ny aşakdaky platformalardan ýükläp, mugt we tölegli meýilnamalardan peýdalanyp bilersiň!",
            // footer links
            terms: "Ulanyş düzgünleri",
            privacy: "Gizlinlik ýörelgesi",
            contact: "Habarlaşmak",
            // terms
            terms_content: `GyzylAlma platformasyna hoş geldiňiz!<br />
                Aşakda GyzylAlma platformasynyň hödürleýän hyzmatlaryny ulanmagyňyzy düzgünleşdirýän şertler bar.<br />
                GyzylAlma platformasyny ulanmazdan öň bu şertleri üns bilen okaň.<br /><br />
                <b>1.Şertleriň kabul edilmegi</b><br /><br />
                GyzylAlma platformasyna girmek bilen, siz bu şertnamanyň ähli şertlerini ýerine ýetirmäge we kabul etmäge razylaşýarsyňyz.<br />
                Şertleriň haýsydyr biri bilen ylalaşmadyk ýagdaýyňyzda, hödürlenýän hyzmatlarymyzdan peýdalanmakdan saklanmagyňyzy haýyş edýäris.<br /><br />
                <b>2.Saýtyň  ulanylyşy</b><br /><br />
                GyzylAlma platformasy müşderilere mobil programmamyzda hödürlenýän hyzmatlary görmek we abunalyk mümkinçiligini berýär.<br /><br />
                <b>3.Agza bolmak we hasabyňyzyň howupsyzlygy</b><br /><br />
                Sahypamyzyň käbir aýratynlyklaryny ulanmak üçin agza bolmagyňyz gerekli bolup biler.<br />
                Agza bolan ýagdaýyňyzda, özüňiz hakda dogry we häzirki maglumatlary bermäge borçlanýarsyňyz.<br />
                Loginiňiziň we parolyňyzyň howpsuzlygy siziň jogapkärçiligiňizde.<br />
                Hasabyňyzyň ulanylyşy we onda bolup geçýän ähli işler siziň jogapkärçiligiňizde.<br /><br />
                <b>4.Gizlinlik</b><br /><br />
                Gizlinlik syýasatymyza laýyklykda, maglumatlaryňyzyň gizlinligini üpjün edýäris.<br />
                Şahsy maglumatlaryňyzy siziň rugsadyňyz bolmazdan üçünji taraplara mälim etmeýäris.<br /><br />
                <b>5.Jogapkärçilik çäkleri</b><br /><br />
                Maglumatlaryň dogrulygyny üpjün etmek üçin elimizden gelen ähli zady etsek-de, hödürlenilýän maglumatlaryň nädogrylygy üçin jogapkärçilik çekmeýäris.<br /><br />
                <b>6.Ulanyş şertlerine üýtgeşmeler girizmek</b><br /><br />
                Ulanyjy şertnamasyna üýtgeşmeler girizme hukugymyzy özümizde saklaýarys. Ähli üýtgeşmeler sahypa giren pursadyndan güýje girýär.<br /><br />
                <b>7. Hyzmatlary ulanmak we abuna ýazylmak</b><br /><br />
                Hyzmatlarymyzdan doly ulanmak üçin size Abuna ýazylmaklyk talap edilýär<br />
                Abuna ýazylanyňyzdan soň, öz saýlan Abuna meýilnamaňyz boýunça, satyn alan senäňizden başlap, bellenilen sene aralygynda hereket edýär<br />
                GyzylAlma platformasyny peýdalanýan wagtyňyzda, Abuna ýazylmak hökmany däl we siz abunalygy öz islegiňiz bilen satyn alyp bilersiňiz<br />
                Abunalyk satyn alan döwrüňizde GyzylAlma platformasynda tehniki işler ýa-da internet prowaýderler tarapyndan wagtlaýynça ýa-da bütinleý çäklendirmeler berilen ýagdaýynda, Abunalyk tölegi yzyna gaýtarylyp berilmeýär. Diňeje platformanyň işe başlamagyna garaşmagyňyz talap edilýär.<br />
                Eger-de mälim sebäplere görä, platformada tehniki iş geçirilse ýa-da geçirilmekçi bolsak, öňünden bildirişler bölüminde biz size habar ugradarys.
                <br /><br />
                <b>8.Şertler bilen ylalaşyk</b><br /><br />
                Biziň sahypamyzy ulanmak bilen, siz bu ulanyş şertlerine razy bolýarsyňyz.<br />
                Eger-de biziň ulanyş şertlerimiz barada soraglaryňyz ýüze çykan ýagdaýynda, sahypamyzda berlen aragatnaşyk maglumatlaryny ulanyp, biziň bilen habarlaşyp bilersiňiz.<br />
                Bu ylalaşyk siziň we GyzylAlma platformasy bilen araňyzdaky şertnama bolup durýar.<br /><br />
                <b>Hormatlamak bilen,
                    GyzylAlma platformasy.<br /><br />
                    Täzelenen senesi: 23.08.2024</b>`,
            // privacy
            privacy_content: `<b>1.Toplanýan maglumatlar:</b><br /><br />
                1.1. Ady. Familiýa<br />
                1.2. Doglan senesi<br />
                1.3. Telefon belgisi<br />
                1.4. E-mail poçta<br />
                1.5. Halaýan zatlary<br /><br />
                <b>2. Maglumat toplamagyň maksatlary:</b><br /><br />
                2.1. Şahsy hasabyňyzy açmak üçin<br />
                2.2. Halaýan temalaryňyzy, ýaş aýratynlygyňyza görä size hödürlemek üçin<br />
                2.3. Hyzmatdan peýdalanýan wagtyňyz üýtgeşmeler ýa-da bildirişler ýüze çykan halatynda siziň bilen habarlaşmak üçin<br />
                2.4. Islegleriňizi has gowy bilmek üçin<br /><br />
                <b>3. Maglumat ýygnamagyň usullary:</b><br /><br />
                3.1. Agza bolan wagtyňyzda berýän maglumatlaryňyzdan maglumat ýygnaýarys.<br />
                3.2. Tarif satyn alanyňyzda berýän maglumatlaryňyzdan maglumat ýygnaýarys.<br />
                3.3. Programmadan gözleg eden wagtyňyzda ýazýan sözleriňizden maglumat ýygnaýarys.<br /><br />
                <b>4.Maglumatlary saklamak we olaryň howpsuzlygy:</b><br /><br />
                4.1. Biz programma üpjünçiligini ulanmak bilen,  siziň şahsy maglumatlaryňyz ulanylanda goramagyň üstünde işleýäris.<br /><br />
                <b>5.Maglumatlary üçünji taraplara bermek:</b><br /><br />
                5.1. Siziň maglumatlaryňyz başga bir üçünji tarapa hiçhili ýagdaýda berilmeýär.<br /><br />
                <b>6. Maglumatlary goramak üçin kanunyň berjaý edilmegi:</b><br /><br />
                6.1. Bu resminama şahsy durmuşyň el degrilmezligi we goragy esasynda TÜRKMENISTANYŇ KANUNYNDA ýazylan.<br /><br /><br />
                <b>Täzelenen senesi: 23.08.2024</b><br />`,
        }
    },
    ru: {
        translation: {
            // home
            title: "GyzylAlma - Ключ к успеху!",
            description: "Вы любите читать?! Если ответ ДА, приложение GyzylAlma как раз для вас!",
            download_prompt: "Скачайте GyzylAlma с платформ ниже и воспользуйтесь бесплатными и платными планами!",
            // footer links
            terms: "Условия",
            privacy: "Политика конфиденциальности",
            contact: "Контакт",
            // terms
            terms_content: `Ниже приведены условия, регулирующие использование вами услуг платформы GyzylAlma.<br />
                Прежде чем использовать платформу GyzylAlma, внимательно ознакомьтесь с этими условиями.<br /><br />
                <b>1. Принятие условий</b><br /><br />
                Войдя на платформу GyzylAlma, вы соглашаетесь выполнять все условия данного соглашения и принимать их.<br />
                Если вы не согласны с каким-либо из условий, мы просим вас воздержаться от использования предоставляемых нами услуг.<br /><br />
                <b>2. Использование сайта</b><br /><br />
                Платформа GyzylAlma предоставляет клиентам возможность просматривать услуги, предлагаемые в нашем мобильном приложении, и оформлять подписку.<br /><br />
                <b>3. Регистрация и безопасность вашего аккаунта</b><br /><br />
                Для использования некоторых функций нашего сайта может потребоваться регистрация.<br />
                При регистрации вы обязуетесь предоставить достоверную и актуальную информацию о себе.<br />
                Безопасность вашего логина и пароля является вашей ответственностью.<br />
                Использование вашего аккаунта и все действия, происходящие в нем, находятся под вашей ответственностью.<br /><br />
                <b>4. Конфиденциальность</b><br /><br />
                Мы обеспечиваем конфиденциальность ваших данных в соответствии с нашей политикой конфиденциальности.<br />
                Мы не раскрываем вашу личную информацию третьим лицам без вашего разрешения.<br /><br />
                <b>5. Ограничение ответственности</b><br /><br />
                Хотя мы делаем все возможное, чтобы обеспечить точность предоставляемой информации, мы не несем ответственности за возможные неточности.<br /><br />
                <b>6. Изменения условий использования</b><br /><br />
                Мы оставляем за собой право вносить изменения в пользовательское соглашение. Все изменения вступают в силу с момента их публикации на сайте.<br /><br />
                <b>7. Использование услуг и подписка</b><br /><br />
                Для полного использования наших услуг требуется подписка.<br />
                После подписки, в зависимости от выбранного вами плана подписки, она будет действовать в течение указанного периода с даты покупки.<br />
                Использование платформы GyzylAlma не требует обязательной подписки, и вы можете приобрести подписку по собственному желанию.<br />
                Если во время приобретения подписки на платформе GyzylAlma проводятся технические работы или ваш интернет-провайдер вводит временные или постоянные ограничения, плата за подписку не возвращается. Вам необходимо просто дождаться возобновления работы платформы.<br />
                В случае проведения технических работ на платформе или при необходимости их проведения, мы заранее уведомим вас через раздел уведомлений.
                <br /><br />
                <b>8. Согласие с условиями</b><br /><br />
                Используя наш сайт, вы соглашаетесь с этими условиями использования.<br />
                Если у вас возникнут вопросы относительно наших условий использования, вы можете связаться с нами, используя контактную информацию, предоставленную на нашем сайте.<br />
                Это соглашение является договором между вами и платформой GyzylAlma.<br /><br />
                <b>С уважением,
                    Платформа GyzylAlma.<br /><br />
                    Дата обновления: 23.08.2024</b>`,
            // privacy
            privacy_content: `<b>1. Собираемые данные:</b><br /><br />
                1.1. Имя и Фамилия<br />
                1.2. Дата рождения<br />
                1.3. Номер телефона<br />
                1.4. Электронная почта<br />
                1.5. Предпочтения<br /><br />
                <b>2. Цели сбора данных:</b><br /><br />
                2.1. Для открытия личного аккаунта<br />
                2.2. Чтобы предлагать вам темы, которые вам нравятся, в соответствии с вашим возрастом<br />
                2.3. Чтобы связаться с вами в случае изменений или уведомлений, возникающих во время использования услуги<br />
                2.4. Чтобы лучше понять ваши предпочтения<br /><br />
                <b>3. Методы сбора данных:</b><br /><br />
                3.1. Мы собираем данные, которые вы предоставляете при регистрации.<br />
                3.2. Мы собираем данные, которые вы предоставляете при покупке тарифа.<br />
                3.3. Мы собираем данные из ваших поисковых запросов в приложении.<br /><br />
                <b>4. Хранение данных и их безопасность:</b><br /><br />
                4.1. Мы используем программное обеспечение для защиты ваших личных данных во время их использования.<br /><br />
                <b>5. Передача данных третьим лицам:</b><br /><br />
                5.1. Ваши данные ни при каких обстоятельствах не передаются третьим лицам.<br /><br />
                <b>6. Соблюдение законодательства о защите данных:</b><br /><br />
                6.1. Этот документ основан на законе ТУРКМЕНИСТАНА о неприкосновенности и защите личной жизни.<br /><br /><br />
                <b>Дата обновления: 23.08.2024</b><br />`,
        }
    },
    en: {
        translation: {
            // home
            title: "GyzylAlma - The Key to Success!",
            description: "Do you love reading?! If the answer is YES, the GyzylAlma app is just for you!",
            download_prompt: "Download GyzylAlma from the platforms below, and enjoy free and paid plans!",
            // footer links
            terms: "Terms",
            privacy: "Privacy Policy",
            contact: "Contact",
            // terms
            terms_content: `Welcome to the GyzylAlma platform!<br />
                Conditions that regulate your use of the services which GyzylAlma platform offers are below.<br />
                Before using GyzylAlma platform read these conditions thoroughly.<br /><br />
                <b>1. Acceptance of the Conditions.</b><br /><br />
                By accessing GyzylAlma platform, you agree to accept this contract.<br />
                In the case you disagree with any of the conditions, please avoid using the services offered.<br /><br />
                <b>2. How to use the site</b><br /><br />
                GyzylAlma platform gives customers the opportunity to subscribe and see the services offered in the mobile app.<br /><br />
                <b>3. Being a member and security of your account </b><br /><br />
                You may need to be a member to use some features of our page.<br />
                In the case you become a member, you are obliged to give accurate and up-to-date information about yourself. <br />
                You are in charge of the security of your login and password. <br />
                You are in charge of everything that is done in your account as well as the usage of it.<br /><br />
                <b>4.Privacy</b><br /><br />
                According to our privacy policy we provide the security of your information.<br />
                We will not expose your personal information to any third parties without your permission.<br /><br />
                <b>5.Limitations of Liability</b><br /><br />
                Although we do our best to ensure the accuracy of the information, we are not responsible for any inaccuracies in the information you provided.<br /><br />
                <b>6. Making changes to the Terms of Use</b><br /><br />
                We reserve the right to make changes to the User Agreement. All changes are effective upon posting.<br /><br />
                <b>7. Using and subscribing to the Services.</b><br /><br />
                You are required to subscribe to take full advantage of our services.<br />
                Once you subscribe, your chosen Subscription plan is valid from the date of purchase until the specified date.<br />
                While using the GyzylAlma Platform, a Subscription is not required and you may purchase a Subscription at your own discretion.<br />
                In the event that the GyzylAlma platform is temporarily or completely restricted by technical work or internet providers during the period of your subscription purchase, the Subscription fee is non-refundable.<br />
                You just need to wait for the platform to start working. If, for any reason, the Platform undergoes or is about to undergo maintenance, we will notify you in advance notices.
                <br /><br />
                <b>8. Agreement to Terms</b><br /><br />
                By using our site, you agree to these terms of use.<br />
                If you have any questions about our terms of use, you can contact us using the contact information provided on our site.<br />
                This Agreement is an agreement between you and the GyzylAlma Platform. <br /><br />
                <b>Sincerely,
                    GyzylAlma Platform.<br /><br />
                    Date Updated: 23.08.2024</b>`,
            // privacy
            privacy_content: `<b>1. Information Collected:</b><br /><br />
                1.1. Name. Surname<br />
                1.2. Date of birth <br />
                1.3. Telephone number <br />
                1.4. E-mail address <br />
                1.5. Things they like<br /><br />
                <b>2. Purposes of Data Collection:</b><br /><br />
                2.1. To open your personal account <br />
                2.2. To offer your favorite topics according to your age.<br />
                2.3. To contact you when there are any changes or announcements made while using the Service.<br />
                2.4. To find out more about your wants.<br /><br />
                <b>3. Methods of Data Collection: </b><br /><br />
                3.1. We collect information from the information you provide when you become a member.<br />
                3.2. We collect information from the information you provide when you purchase a tariff.<br />
                3.3. We collect information from the words you type when you search on the app.<br /><br />
                <b>4. Data retention and security: </b><br /><br />
                4.1. We work on protecting your personal information by using software.<br /><br />
                <b>5. Transfer of information to third parties:</b><br /><br />
                5.1. Your information will not be passed on to any third parties under any circumstances.<br /><br />
                <b>6. Compliance with data protection law:</b><br /><br />
                6.1. This document is written in the LAW of TURKMENISTAN based on privacy and protection of privacy.<br /><br /><br />
                <b>Date Updated: 23.08.2024</b><br />`,
        }
    }

};

export default translations;