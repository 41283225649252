import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../locales/translations';

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: 'tk', // Başlangyç dil
    fallbackLng: 'tk', // Dil tapylmasa haýsy dili ulanjakdygyny kesgitlär
    interpolation: {
      escapeValue: false, // React ulgamy bilen howpsuz integrasiýa
    },
  });

export default i18n;

