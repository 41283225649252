import React from 'react'
import { Helmet } from 'react-helmet';
import { useHandleLanguageChange } from '../utils/lng_get';
import { useTranslation } from 'react-i18next';

function Privacy() {
    useHandleLanguageChange();
    const { t } = useTranslation();
    const privacyContent = { __html: t('privacy_content') };

    return (
        <>
            <Helmet>
                <title>{t('privacy')}</title>
            </Helmet>
            <div className='nonecontent'>
                <b>{t('privacy')}</b><br /><br />
                <div dangerouslySetInnerHTML={privacyContent} />
            </div>
        </>
    )
}

export default Privacy