import React from 'react';
import { Helmet } from 'react-helmet';
import { useHandleLanguageChange } from '../utils/lng_get';
import { useTranslation } from 'react-i18next';

function Contact() {
  useHandleLanguageChange();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('contact')}</title>
      </Helmet>
      <div className='nonecontent'>
        <b>{t('contact')}:</b><br /><br />
        Tel: <b>+993 64-27-97-65</b><br />
        Imo: <b>+993 64-27-97-65</b><br />
        Start: <b>@gyzylalmatm</b><br /><br />
      </div>
    </>
  );
}

export default Contact;
