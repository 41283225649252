import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from "react-router-dom";
import NextTopLoader from 'nextjs-toploader';
import Home from './pages/home';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Contact from './pages/contact';
import { useTranslation } from 'react-i18next';

function Gyzylalma() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { lang_slug } = useParams();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'tk';

    if (lang_slug) {
      if (lang_slug !== savedLanguage) {
        i18n.changeLanguage(lang_slug);
        localStorage.setItem('language', lang_slug);

        const newPath = `/${lang_slug}${window.location.pathname.replace(/^\/\w+/, '')}`;
        if (newPath !== window.location.pathname) {
          navigate(newPath, { replace: true });
        }
      } else {
        i18n.changeLanguage(savedLanguage);
      }
    } else {
      i18n.changeLanguage(savedLanguage);
      const newPath = `/${savedLanguage}${window.location.pathname.replace(/^\/\w+/, '')}`;
      navigate(newPath, { replace: true });
    }
  }, [i18n, navigate, lang_slug]);

  const changeLanguage = (lang) => {
    if (['en', 'ru', 'tk'].includes(lang)) {
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
      const newPath = `/${lang}${window.location.pathname.replace(/^\/\w+/, '')}`;
      navigate(newPath, { replace: true });
    }
  };

  return (
    <>
      <NextTopLoader color="#ff0000"
        initialPosition={0.08}
        crawlSpeed={200}
        height={4}
        crawl={true}
        showSpinner={false}
        easing="ease"
        speed={500}
        shadow="0 0 10px #ff0000,0 0 5px #ff0000"
        template='<div class="bar" role="bar"><div class="peg"></div></div>'
        zIndex={1600}
        showAtBottom={false} />
      <Routes>
        <Route path="/:lang_slug/" element={<Home changeLanguage={changeLanguage} />} />
        <Route path="/:lang_slug/terms" element={<Terms changeLanguage={changeLanguage} />} />
        <Route path="/:lang_slug/privacy-policy" element={<Privacy changeLanguage={changeLanguage} />} />
        <Route path="/:lang_slug/contact" element={<Contact changeLanguage={changeLanguage} />} />
      </Routes>
    </>
  );
}

export default Gyzylalma;
